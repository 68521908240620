import { useState } from 'react';

const useSaveHighScore = (token) => {
  const [errorsaving, setError] = useState(null);
  const [loadingsaveing, setLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const saveHighScore = async (phone, gamename, level, score, withHistory) => {
    setLoading(true);
    setError(null);
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL_FUNCTION}/saveGameDataForUsers`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            gamename,
            phone,
            life: 10,
            level: level || 1,
            score: score || 1,
            history: [],
            with_history: withHistory,
          }),
        }
      );

      const data = await res.json();
      setResponse(data);
      if (data.status === 405) {
        setError(data.status);
      }
      
      return data;
    } catch (e) {
      console.error(e);
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  return { saveHighScore, errorsaving, loadingsaveing, response };
};

export default useSaveHighScore;

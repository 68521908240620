import React, { useState, useEffect } from "react";
import { Carousel, Dropdown } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import useGames from "../../customHooks/useGames"; // Adjust the import according to your project structure
import { IconsLoader } from "../../imports/icon"; // Adjust the import according to your project structure
import { change } from "../../redux/lang"
import { useEnvironment } from "../../context/EnvironmentContext";



const Index = () => {
    const language = useSelector((state) => state.lang.language);
    const isLogedIn = useSelector((state) => state.user.isLogedIn);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [isInstallable, setIsInstallable] = useState(false);
    const { isWebViewApp } = useEnvironment();
    const auth = useSelector(state => state?.user.value)
    const { games, isLoadingSet } = useGames(auth?.token);



    const dispatch = useDispatch();


    const changeLanguage = (lang) => {
        dispatch(change(lang));
      };

    const items = [
        {
          key: 1,
          value: "am",
          label: <a className="xl:text-[1vw]" onClick={() => changeLanguage("am")}>Amharic</a>,
        },
        {
          key: 2,
          value: "en",
          label: <a className="xl:text-[1vw]" onClick={() => changeLanguage("en")}>English</a>,
        },
        {
          key: 3,
          value: "or",
          label: <a className="xl:text-[1vw]" onClick={() => changeLanguage("or")}>Oromo</a>,
        },
        {
          key: 4,
          value: "af",
          label: <a className="xl:text-[1vw]" onClick={() => changeLanguage("af")}>Afar</a>,
        },
        {
          key: 5,
          value: "ti",
          label: <a className="xl:text-[1vw]" onClick={() => changeLanguage("ti")}>Tigrinya</a>,
        },
        {
          key: 6,
          value: "so",
          label: <a className="xl:text-[1vw]" onClick={() => changeLanguage("so")}>Somali</a>,
        },
      ];

    useEffect(() => {
        const handler = (e) => {
            e.preventDefault();
            setDeferredPrompt(e);
            setIsInstallable(true);
        };
        window.addEventListener('beforeinstallprompt', handler);
        return () => {
            window.removeEventListener('beforeinstallprompt', handler);
        };
    }, []);

    const handleInstallClick = async () => {
        if (!deferredPrompt) return;

        // Show the install prompt
        deferredPrompt.prompt();

        // Wait for the user to respond to the prompt
        const choiceResult = await deferredPrompt.userChoice;
        if (choiceResult.outcome === 'accepted') {
            // console.log('User accepted the install prompt');
        } else {
            // console.log('User dismissed the install prompt');
        }

        // Clear the deferredPrompt so it can only be used once
        setDeferredPrompt(null);
        setIsInstallable(false);
    };
    useEffect(() => {
        const handleBeforeInstallPrompt = (e) => {
            e.preventDefault(); // Prevent the mini-infobar from appearing on mobile
            setDeferredPrompt(e); // Save the event so it can be triggered later.
        };
        window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
        return () => {
            window.removeEventListener(
                "beforeinstallprompt",
                handleBeforeInstallPrompt
            );
        };
    }, []);



    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // from https://react-slick.neostack.com/docs/example/custom-arrows
    const SampleNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className="w-20  absolute z-30 top-[55.5vh] md:top-[54vh] cursor-pointer right-[-23px] pl-1 hover:bg-[#0e0e0e6e] rounded-3xl"
                style={{ ...style, display: "block" }}
                onClick={onClick}
            >
                <IconsLoader icon={"carausal-next"} width={50} height={50} />
            </div>
        );
    };

    const SamplePrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className="w-20  absolute z-30 top-[54vh] left-[-17px] pl-4 cursor-pointer md:visible invisible hover:bg-[#0e0e0e6e] rounded-3xl"
                style={{ ...style, display: "block" }}
                onClick={onClick}
            >
                <IconsLoader icon={"carausal-previous"} width={50} height={50} />
            </div>
        );
    };

    const settings = {
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    };

    useEffect(() => {
    }, [isLoadingSet]);

    if (!isLoadingSet) {
        return (
            <Carousel
                dotPosition={`${screenWidth < 570 ? "left" : "bottom"}`}
                arrows
                draggable
                {...settings}
                fade
                className={`w-[100vw] ${false ? 'h-[100vh]' :  'h-[90vh]'} sm:h-[93vh] overflow-hidden md:h-[94vh]`}
            >
                {games.map((item, index) => {
                    const imageUrl = screenWidth < 570 ? item?.image[1] : item?.image[0];
                    return (
                        <div
                            key={index}
                            className={`w-[100vw]  md:relative ${isWebViewApp ? 'h-[100vh]' : 'h-[90vh]'}  sm:h-[100vh] md:h-[95vh] bg-cover bg-center p-0 m-0`}
                        >
                            <img src={imageUrl} className={`w-[100%] ${false ? 'h-[100vh]' :  'h-[90vh]'} md:h-[100%] absolute`} alt="data" />
                            <div className="w-[100%] min-h-[95vh] md:min-h-[95vh] flex flex-col justify-between self-start absolute bg-gradient-to-b from-[#000000ad] via-[#00000035] to-[#000000a7] pt-5">
                                <div className="flex flex-row justify-between items-center">
                                    <div className="h-[50px] px-2 flex flex-row items-center md:px-12 pt-10 md:pt-20 xl:pt-[10vh]">
                                        <div className="relative p-2 rounded-xl border-[4px] border-transparent">
                                            <div className="absolute inset-0 rounded-xl border-[4px] border-transparent before:absolute before:inset-0 before:rounded-xl before:border-[4px] before:border-transparent before:bg-gradient-to-r before:from-transparent before:via-white before:to-transparent before:bg-[length:200%_auto] before:animate-shimmer-border"></div>
                                            <div className="relative z-10">
                                                <img
                                                    src={item.icon}
                                                    width={100}
                                                    alt="GameImage"
                                                    height={80}
                                                    className="w-[50px] object-contain h-[50px] md:w-[100px] md:h-[100px] rounded-md xl:w-[5vw] xl:h-[10vh]"
                                                />
                                            </div>
                                        </div>
                                        <h1 className="ml-5 font-milkb text-center text-2xl md:text-4xl uppercase text-[white] 2xl:text-[2vw]">
                                            {item.name[language.lang]}
                                        </h1>
                                    </div>
                                    <div className="h-[50px] xl:h-[10v] px-2 flex flex-row items-center md:px-12 pt-10 md:pt-20 xl:w-[8vw]">
                                        {/* <h1 className="px-3 uppercase text-white font-milkb text-sm md:text-md">{lang(language.languageName)}</h1> */}
                                        <Dropdown
                                            menu={{
                                                items,
                                            }}
                                            placement="bottomLeft"
                                            className=" border-[#ff7b00] bg-[#8DC73F] hover:bg-[#ff7b00] border-[0px] shadow-2xl shadow-black p-2 "
                                            arrow
                                        >
                                            <a className="text-sm xl:text-[1vw] md:!p-2 rounded-3xl p-1 hover:text-white xl:!p-3  text-[white] cursor-pointer md:text-sm text-center self-center font-milkr">
                                                {language?.lang}
                                            </a>
                                        </Dropdown>
                                    </div>
                                </div>
                                <Link
                                    to={
                                        isLogedIn
                                            ? {
                                                pathname: "/playing",
                                                search: `?name=${item.name["en"]}&url=${item.gameUrl
                                                    }&with_history=${item.with_history || false}`,
                                            }
                                            : "/auth"
                                    }
                                    className="flex flex-col justify-center align-center items-center mt-[30vh] sm:mt-[20vh]"
                                >
                                    <div className=" w-[50%] md:w-[30%] max-h-[50px]  bg-gradient-to-r from-[#FE9902] via-[#FE9902] to-[#FE9902] shadow-xl rounded-[5px] overflow-hidden">
                                        <div className="flex flex-row justify-end px-5">
                                            <IconsLoader icon="playmask" />
                                        </div>
                                    </div>
                                    <div className="absolute text-white hover:text-white md:text-xl font-milkb">
                                        {language.playNow}
                                    </div>
                                </Link>
                                <div className="self-center mb-20 sm:visible text-center">
                                    <p className={`text-white text-center text-xs md:text-[16px] max-w-[70vw] uppercase font-milkb ${isInstallable ? '' : 'pb-10'}`}>
                                        
                                    </p>
                                    {isInstallable && (
                                        <button
                                            onClick={handleInstallClick}
                                            className="text-[#FF9900] text-center font-milkb mt-3 text-xs md:text-lg hover:text-[#ff7b00]"
                                        >
                                            {language.download}
                                        </button>
                                    )}

                                    <div className="flex self-center flex-col">
                                        {/* <Link
                                            className="text-[#FF9900] text-center font-roboto capitalize hover:text-[#ffd392]"
                                            to="/privacy"
                                        >
                                            {language.privecyPolicy}
                                        </Link>
                                        <Link
                                            className="text-[#FF9900] text-center hover:text-[#ffd392]"
                                            to="/terms-and-service"
                                        >
                                            {language.termandservice}
                                        </Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }

                )}

            </Carousel>
        );
    } else {
        return (
            <div className="overflow-hidden flex flex-col justify-center items-center min-h-[80vh]">
                <div role="status">
                    <svg aria-hidden="true" class="inline w-14 h-20 text-gray-200 animate-spin dark:text-gray-600 fill-[#FE9902]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                    </svg>
                </div>
            </div>
        );
    }
};

export default Index;

import React, { useEffect, useState } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import OtpInput from "react-otp-input";
import {
  login
} from "../../redux/auth";
import { IconsLoader } from "../../imports/icon";
import { useEnvironment } from "../../context/EnvironmentContext";
// New Functions
import { useEventsListenersGTAG } from "./customHooks/useUtility";
import useSignInHook from "./customHooks/useSigninHook";
import Loading from "./components/Loading";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";





export default function PhoneScrean() {
  const [value, setValue] = React.useState("");
  const [api, contextHolder] = notification.useNotification();
  const [otp, setOtp] = useState(false)
  const [otpCheck, setOTPCheck] = React.useState(false)
  const [otpValue, setOtpValue] = useState('')
  const [error, setError] = useState(null)
  const { isWebViewApp } = useEnvironment();
  const navigation = useNavigate()
  // New Handler
  let { userAuthentication, isSubscribed, loading, userObj } = useSignInHook()
  const dispatch = useDispatch();
  const language = useSelector((state) => state.lang.language);
  const [subscribers, setSubsribe] = useState("")

  const validateOTPcode = async (phone, otp) => {
    try {
      setOTPCheck(true)
      const response = await fetch(
        `${process.env.REACT_APP_API_URL_FUNCTION}/registerDevice`,
        {
          method: "POST", // or 'PUT'
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ phone: phone, otp: otp }),
        }
      );
      const res = await response.json();
      useEventsListenersGTAG({
        action: "OTP Validations",
        category: "auth",
        label: "otp request validate",
        value: value,
      });
      if (res?.error) {
        setError(res.error)
        setOTPCheck(false)

        return null;
      } else if (res?.token) {
        await dispatch(login(res))
        setOTPCheck(false)
        navigation({ pathname: '/games' })
      } else {
        setOTPCheck(false)

        return null;
      }
    } catch (e) {
      setOTPCheck(false)
      return null;
    }
  };


  const openNotificationWithIcon = (type, title, body, phone) => {
    api[type]({
      message: title,
      description: body,
    });
  };

  const signInPhone = async (phone) => {
    if (!phone) {
      phone = ''
    }
    if (isValidPhoneNumber(phone)) {
      useEventsListenersGTAG({
        action: 'Login Attempted',
        category: "Authentication",
        label: `${phone} Attempted to login`,
        value: phone
      })
      let user = await userAuthentication(phone)

      if (user?.isSubscribed) {

        useEventsListenersGTAG({
          action: 'Subscribed Login Attempted',
          category: "Authentication",
          label: `${phone} Attempted to login`,
          value: phone
        })

        openNotificationWithIcon(
          "success",
          "Welcome back, we just sent you an otp to login",
          `${phone} is subscribed to kinet store`
        );
        setOtp(true)
      }
      else {
        useEventsListenersGTAG({
          action: 'New User Login Attempted',
          category: "Authentication",
          label: `${value} Attempted to login`,
          value: value
        })

        openNotificationWithIcon(
          "info",
          "Please Subscribe to gain access or explore other options with our telegram bot",
          `${value} is currently not  subscribed to kinet store`
        );
      }

    } else {
      // console.log((isValidPhoneNumber(phone, 'ET')))
    }
    // Primery Validation 
  };
  useEffect(() => {
    if (otpValue.length >= 4) {
      validateOTPcode(value, otpValue)

    }
  }, [otpValue])

  useEffect(() => {
    if (isWebViewApp) {
      // console.log("i am now here", window.parent)
    }
  }, [])

  const setOtpFucn = (e) => {
    setOtpValue(e)
  }

  useEffect(() => {
    setSubsribe(language.subscribeTo)
  }, [])

  return (
    <div className="flex flex-col  justify-center w-[100%]   bg-[#242C37] rounded-md">
      {contextHolder}
      <div className="px-0 flex flex-col  h-[100%] pt-8  justify-center w-[100%] mr-10">
        <h1 className="text-[10vw] md:text-[2vw] text-center text-white font-milkb">
          <h1 className="text-[#8DC73F]">{language.ethiotelebrandingtowelcomedelusion}</h1>
          <h1>{language.ethiotelebrandingname}</h1>
          {/* {language.welcome1} */}
        </h1>
        <h5 className="capitalize text-lg xl:text-[1vw] xl:mb-[1vh] py-2 mt-2 font-milkr text-white">
          {language.userorphone}
        </h5>
        <PhoneInput
          className="bg-[#fcfcfc] border-[1px] border-[#3B3B3B] text-[black] px-2 py-2 xl:text-[1vw] rounded-md "
          placeholder={language.enterphonenumber}
          defaultCountry="ET"
          valid
          color="white"
          value={value}
          onChange={(e) => {
            setOtpValue('')
            setValue(e)
            signInPhone(e)
          }}
        />
        {/* <a href="https://t.me/kinetGamesBot" target="_blank" className="text-right py-3 xl:text-[1vw] xl:py-[2vh]   hover:text-[#FE9902] text-[white] font-milkr text-xs">
          {language.telegramLogin}
        </a> */}
      </div>
      {otp ? <div className="mb-5 mt-10">
        <OtpInput
          value={otpValue}
          onChange={(e) => setOtpFucn(e)}
          isDisabled={otpCheck}
          numInputs={4}
          inputStyle={{
            padding: 5,
            color: "black",
            width: "22%",
            borderRadius: 5,
            outlineColor: "#FE9902",
          }}
          renderSeparator={<span className="bg-gray w-3 md:w-5"> </span>}
          renderInput={(props) => <input {...props} />}
        />
      </div> : null}
      {error && <p className="text-red-500 text-center">{error}<a onClick={async() =>{
        await setOtp(false)
        await setError(null)
        await setOtpValue('')
        await signInPhone(value)
      }} className="px-2 font-extralight cursor-pointer text-[#FE9902]">Resend</a></p>}
      {otpCheck && <Loading />}
      <p className={`text-white pt-10 text-center text-xs md:text-[13px] pb-5 max-w-[100vw] font-milkr`}>
        {language.rules}
      </p>
      {otp ? null : <a
        disabled={loading}
        href={"sms:9871?body=ok"}
        className="flex flex-col justify-center xl:h-[5vh] align-bottom items-center shadow-lg z-0 bg-transparent"
      >
        <div className="w-[100%] max-h-[50px]  bg-gradient-to-r py-[0px] xl:py-[2vh]  from-[#8DC73F] via-[#8DC73F]] to-[#8DC73F] shadow-xl rounded-[5px] overflow-hidden">
          <div className="flex flex-row justify-end px-5">
            <IconsLoader icon="playmask" />
          </div>
        </div>
        <div className="absolute xl:text-[1vw]  text-[white] font-milkr text-md uppercase ">
          {loading ? <Loading /> : <p className=" text-white">{language.subscribeTo}</p>}
        </div>
      </a>}

      <div className="self-center sm:visible text-center w-[100%] pt-10 pb-10">

        <div className="flex flex-col justify-center">
          <h1 className="text-white font-extralight">
            By entering this site you confirm that you accept our
            <Link
              className="text-[#8DC73F] text-left hover:text-[#FE9902] px-1"
              to="/terms-and-service"
            >
              {language.termandservice}
            </Link>and have read our<Link
              className="text-[#8DC73F] text-left font-roboto capitalize hover:text-[#FE9902] px-1"
              to="/privacy"
            >
              {language.privecyPolicy}
            </Link></h1>
        </div>
      </div>
    </div>
  );
}


import { useState, useEffect } from 'react';

const useChangeUserData = (token) => {
    let [usernames, setUsername] = useState("")
    const [loading, setLoading] = useState(true)

    const changeUserNameOnline = async (phone, username) => {
      console.log("username", username, phone);
      
      if (phone == "") {
        return
      }
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL_FUNCTION}/changeUserName`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ phone, username }),
          }
        );

        const data = await response.json();
        setUsername(data.username)
        setLoading(false)
        return data
      } catch (e) {
        console.log("Failed to", e)
        return e
      } finally {
        setLoading(false)
      }
    };

  return { usernames, changeUserNameOnline, loading };
};

export default useChangeUserData;

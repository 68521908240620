import { useState, useEffect, useCallback } from "react";

const sortTournament = (a, b) => {
  if (a.index < b.index) return -1;
};

const useGames = (token) => {  
  const [games, setGames] = useState([]);
  const [error, setError] = useState(false);
  const [isLoadingSet, setIsLoading] = useState(true);
  const getGames = useCallback(async () => {    
    try {
      const response = await fetch(process.env.REACT_APP_API_URL_FUNCTION + "/listActiveGames", {
      headers: {
        Authorization: `Bearer ${token}`
      }
      });
      const gamesData = await response.json();
      setGames(gamesData?.games.sort(sortTournament));
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setError(true);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getGames();
  }, []);


  return { games, isLoadingSet };
};

export default useGames;